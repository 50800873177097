<template lang='pug'>
article.on-order
  .on-order__cont.cont
    h1.on-order__caption.caption Корпоративные подарки
    .on-order__gallery
      on-order-image-rectangle(path="on-order-1", alt="Корпоративные подарки из шоколада - фото 1", title="Корпоративные подарки из шоколада - фото 1", :loading-lazy="false")
      on-order-image-square(path="on-order-2", alt="Корпоративные подарки из шоколада - фото 2", title="Корпоративные подарки из шоколада - фото 2", :loading-lazy="false")
      on-order-image-rectangle(path="on-order-3", alt="Корпоративные подарки из шоколада - фото 3", title="Корпоративные подарки из шоколада - фото 3", :loading-lazy="false")
      on-order-image-square(path="on-order-4", alt="Корпоративные подарки из шоколада - фото 4", title="Корпоративные подарки из шоколада - фото 4", :loading-lazy="false")
      on-order-image-rectangle(path="on-order-5", alt="Корпоративные подарки из шоколада - фото 5", title="Корпоративные подарки из шоколада - фото 5", :loading-lazy="false")
    h2.on-order__title.title Оптовые поставки шоколадных изделий от&nbsp;производителя по&nbsp;России и СНГ
    ul.on-order__supply-list
      li.on-order__supply-item(v-for="(item, i) in supplyList", :key="i") {{item}}
    h2.on-order__title.on-order__title_choco-types.title Сувенирный шоколад
    p.on-order__text Шоколадные барельефы и фигурки, посвящённые достопримечательностям, памятникам архитектуры, историческим местам вашего города.
    .on-order__gallery
      on-order-image-rectangle(path="on-order-6", alt="Сувениры из шоколада - фото 1", title="Сувениры из шоколада - фото 1")
      on-order-image-square(path="on-order-7", alt="Сувениры из шоколада - фото 2", title="Сувениры из шоколада - фото 2")
      on-order-image-rectangle(path="on-order-8", alt="Сувениры из шоколада - фото 3", title="Сувениры из шоколада - фото 3")
      on-order-image-square(path="on-order-9", alt="Сувениры из шоколада - фото 4", title="Сувениры из шоколада - фото 4")
      on-order-image-rectangle(path="on-order-10", alt="Сувениры из шоколада - фото 5", title="Сувениры из шоколада - фото 5")
    h2.on-order__title.on-order__title_choco-types.title Промо-шоколад
    p.on-order__text
      | Брендированный сладкий комплимент, в виде маленьких плиток шоколада с лого, монет, медалей, визитных карточек.
      | Вкусный и нетривиальный рекламный промо-подарок для выставки, на стойке ресепшена, в переговорной, возле кассы
      | или у столиков у примерочных.
    .on-order__gallery
      on-order-image-big-square(path="on-order-11", alt="Брендированный шоколад - фото 1", title="Брендированный шоколад - фото 1")
      on-order-image-big-square(path="on-order-12", alt="Брендированный шоколад - фото 2", title="Брендированный шоколад - фото 2")
      on-order-image-big-square(path="on-order-13", alt="Брендированный шоколад - фото 3", title="Брендированный шоколад - фото 3")
      on-order-image-big-square(path="on-order-14", alt="Брендированный шоколад - фото 4", title="Брендированный шоколад - фото 4")
  .on-order__whom
    .on-order__cont.cont
      h2.on-order__whom-title.title Для кого?
      ul.on-order__whom-list
        li.on-order__whom-item(v-for="(item, i) in whomlist", :key="i") {{item}}
  .on-order__cont.cont
    h2.on-order__contacts-title.title Хотите получить оригинальные фигурки из&nbsp;шоколада?
    p.on-order__contacts-text
      | Мы готовы реализовать Ваши самые смелые фантазии по изготовлению фигурного шоколада. Мы создадим любые изделия
      | из шоколада на заказ, так как мы используем в своем производстве самое новейшее оборудование и технологии,
      | которые позволяют создавать шоколадные произведения искусства.
    p.on-order__contacts-text
      | Если вы хотите заказать шоколадные фигурки, просто позвоните по номеру {{phone.mask}} —
      | мы поможем вам определиться с видом, размером и количеством шоколадных фигурок, расскажем про вкусовые
      | особенности и самые популярные, чаще всего заказываемые варианты.
    p.on-order__list-title.text-big
      | Предоставляем скидки для корпоративных заказов:
    ul.on-order__list
      li.on-order__item.text-big
        | От 10 штук одной позиции - 10%
      li.on-order__item.text-big
        | Индивидуальные условия - 15%
    ul.on-order__contacts-list
      li.on-order__contacts-item
        span.on-order__contacts-name Свяжитесь с нами по почте
        a.on-order__contacts-link.on-order-contacts__link_email.link-contact(:href="mail.link") {{mail.mask}}
      li.on-order__contacts-item
        span.on-order__contacts-name Или по телефону/whatsapp
        a.on-order__contacts-link.link-contact(:href="phone.onlineOrder.link") {{phone.onlineOrder.mask}}
        .on-order__social-box
          a.on-order__social(:class="`on-order__social_${whatsAppLink.mod}`", :href="whatsAppLink.link", :title="whatsAppLink.name", target="_blank", rel="noopener nofollow noreferrer")
</template>

<script>
import OnOrderImageSquare from './OnOrderImageSquare'
import OnOrderImageBigSquare from './OnOrderImageBigSquare'
import OnOrderImageRectangle from './OnOrderImageRectangle'
import { mail, phone } from '../../assets/scripts/variables'
import { whatsAppLink } from '../../assets/scripts/socials'

export default {
  name: 'OnOrder',
  components: { OnOrderImageSquare, OnOrderImageBigSquare, OnOrderImageRectangle },
  data () {
    return {
      supplyList: [
        'Уникальный дизайн',
        'Брендирование',
        'Детальное оформление',
        'Короткие сроки',
        'Заказ от 10 штук'
      ],
      whomlist: [
        'Для компаний как корпоративный презент',
        'Для сувенирных лавок в туристических местах',
        'Для бизнеса как промоушн'
      ],
      mail,
      phone,
      whatsAppLink
    }
  }
}
</script>

<style lang='sass'>
@import 'src/assets/styles/components/on-order'
</style>
